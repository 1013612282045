import { memo, useMemo } from "react"
import { Box, Heading, Grid, GridItem } from "@chakra-ui/react"
import { ProductCard } from "@components/ProductCard"
import { useShopifyProductRaw } from "@app/hooks/useShopify"

type SearchProductRecommendationsProps = {
  recommendedProducts?: GatsbyTypes.SanityPageSearch["recommendedProducts"]
}

const SearchProductRecommendations: React.FC<SearchProductRecommendationsProps> = ({ recommendedProducts }) => {
  const { getRawProduct } = useShopifyProductRaw()

  const hasProducts = useMemo(() => !!recommendedProducts?.products?.length, [recommendedProducts?.products?.length])

  const mappedProducts = useMemo(
    () =>
      recommendedProducts?.products?.length
        ? recommendedProducts?.products?.map((product: any) => ({
            ...product,
            metafields: getRawProduct(product)?.product?.metafields,
          }))
        : [],
    [getRawProduct, recommendedProducts]
  )

  if (!hasProducts) return null

  return (
    <Box>
      {recommendedProducts?.title && (
        <Heading as="h2" size="h4" mb={6}>
          {recommendedProducts?.title}
        </Heading>
      )}

      <Grid
        templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"]}
        gridColumnGap={2}
        gridRowGap={[4, 9, 9, 6]}
      >
        {mappedProducts?.map((product, idx) => (
          <GridItem key={`${product?._id}-${idx}`} borderBottom={["1px", "none"]} pb={[4, 0]}>
            <ProductCard
              item={product}
              wishListButtonProps={{
                showOutline: false,
              }}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}
const MemoSearchProductRecommendations = memo(SearchProductRecommendations)
export { MemoSearchProductRecommendations as SearchProductRecommendations }
