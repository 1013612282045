import { memo } from "react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { Text, HStack, Tag } from "@chakra-ui/react"
import { useSearch } from "@usereactify/search"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

type SearchSuggestedTermsProps = {
  terms?: GatsbyTypes.SanityPageSearch["suggestedSearchTerms"]
}

const SearchSuggestedTerms: React.FC<SearchSuggestedTermsProps> = ({ terms }) => {
  const locales = useLocale(LOCALE_KEYS.SEARCH)
  const { setSearchTerm } = useSearch()
  const {
    helpers: { isBrowser },
  } = useCore()
  const {
    settings: { params },
  } = useConfigContext()

  const handleTermClick = (term: string) => {
    if (isBrowser) {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${params.searchQuery}=${term}`
      window.history.pushState({ path: newurl }, "", newurl)
    }
    setSearchTerm(term)
  }

  if (!terms) return null

  return (
    <>
      <HStack wrap="wrap" columnGap={[1.5, 2]} rowGap={2} spacing={0}>
        <Text fontSize={["sm", "md"]} w={["full", "auto"]} mb={[3, 0]}>
          {locales?.searchPageSuggestedSearchesTitle}
        </Text>
        {terms?.map(({ _key, term }: any) => (
          <Tag key={_key} variant="search-suggested" cursor="pointer" onClick={() => handleTermClick(term)}>
            {term}
          </Tag>
        ))}
      </HStack>
    </>
  )
}

const MemoSearchSuggestedTerms = memo(SearchSuggestedTerms)
export { MemoSearchSuggestedTerms as SearchSuggestedTerms }
