import { useEffect, useRef } from "react"
import { useBrandCollection } from "@app/hooks/useBrandCollection"
import { Box, HStack, Container, Text, Heading, Image, Stack, useBoolean } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { CustomLink } from "../Link"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { memo } from "react"

import type { Props } from "@app/pages/search"
import type { PageProps } from "@root/types/global"

type SearchBrandBannerProps = {
  brands: PageProps<Props>["brands"]
  setIsBrandCollection: (arg: boolean) => void
}

function countLines(el: any, lineHeight: number) {
  if (el) {
    const divHeight = el.offsetHeight
    const lines = divHeight / lineHeight
    return lines
  }
  return 0
}

const SearchBrandBanner: React.FC<SearchBrandBannerProps> = ({ brands, setIsBrandCollection }) => {
  const { brandCollection, hasMatchingBrandCollection } = useBrandCollection({ brands })
  const {
    settings: { routes },
  } = useConfigContext()
  // const locales = useLocale(LOCALE_KEYS.SEARCH)
  const collectionLocales = useLocale(LOCALE_KEYS.COLLECTION)
  const readMoreTextRef: any = useRef()

  const [showAllContent, setShowAllContent] = useBoolean()

  useEffect(() => {
    if (hasMatchingBrandCollection) {
      setIsBrandCollection(true)
    }
  }, [hasMatchingBrandCollection, setIsBrandCollection])

  if (!hasMatchingBrandCollection) return null

  const { title, descriptionHtml, image, shopify }: any = brandCollection
  const handle = shopify?.handle || ""
  const collectionUrl = `${routes.COLLECTION}/${handle}`

  const showMoreContentTxt = collectionLocales?.showMoreContent || "Read More"
  const showLessContentTxt = collectionLocales?.showLessContent || "Show Less"

  const NUM_OF_LINES = 4
  const brandDescriptionLineHeight = 24
  const brandDescriptionLineCount = countLines(readMoreTextRef?.current, brandDescriptionLineHeight)
  const showReadMore = brandDescriptionLineCount > NUM_OF_LINES

  // ***** is the custom divider that the client uses in shopify collections
  const [headerContent] = descriptionHtml?.split("*****") || descriptionHtml || []

  return (
    <Box bg="brand.offWhite" pb={[7, 7, 14]}>
      <Container>
        <HStack spacing={[3.5, 3.5, 12]} alignItems="flex-start" borderBottom={["1px", "none"]} pb={[7, 0]}>
          <Box
            h={[24, 24, 49]}
            minW={[24, 24, 49]}
            w={[24, 24, 49]}
            borderRadius="round"
            bg="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={[-8, -8, -10]}
          >
            <CustomLink to={collectionUrl}>
              <Image src={image} maxW={[15, 15, 32]} />
            </CustomLink>
          </Box>
          <Box pt={2.5}>
            <Heading as="p" size="h3" pb={[2, 2, 6]}>
              {title}
            </Heading>
            <Stack direction={["column", "column", "row"]} alignItems="flex-start" spacing={[2, 2, 8]}>
              {descriptionHtml && (
                <Box position="relative">
                  <Text
                    fontSize="md"
                    lineHeight={`${brandDescriptionLineHeight}px`}
                    dangerouslySetInnerHTML={{ __html: headerContent }}
                    noOfLines={showAllContent ? undefined : NUM_OF_LINES}
                    ref={readMoreTextRef}
                  />
                  {showReadMore && (
                    <Text
                      as="span"
                      fontWeight={700}
                      position="absolute"
                      bottom={-5}
                      right={0}
                      cursor="pointer"
                      onClick={setShowAllContent.toggle}
                      fontSize={["sm", "md"]}
                      lineHeight={1.6}
                    >
                      {showAllContent ? showLessContentTxt : showMoreContentTxt}
                    </Text>
                  )}
                </Box>
              )}
              {/* {locales?.searchResultsShopBrandButton && (
                <CustomLink to={collectionUrl} w={["full", "auto", "auto"]} pt={4}>
                  <Button variant="solid" size="sm" px={12} w={["full", "auto", "auto"]}>
                    {locales?.searchResultsShopBrandButton}
                  </Button>
                </CustomLink>
              )} */}
            </Stack>
          </Box>
        </HStack>
      </Container>
    </Box>
  )
}

const MemoSearchBrandBanner = memo(SearchBrandBanner)
export { MemoSearchBrandBanner as SearchBrandBanner }
