import { graphql, PageProps } from "gatsby"
import Page from "@components/Search/Search"

export type Props = PageProps<GatsbyTypes.PageSearchQuery, GatsbyTypes.PageSearchQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageSearch {
    page: sanityPageSearch {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      recommendedProducts: _rawRecommendedProducts(resolveReferences: { maxDepth: 2 })
      suggestedSearchTerms {
        _key
        term
      }
    }
    brands: allSanityCollection(filter: { shopify: { published: { eq: true } }, isBrand: { eq: true } }) {
      edges {
        node {
          ...SanityCollectionFragment
        }
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
