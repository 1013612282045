import { useState, memo, useCallback } from "react"
import { Box, Flex, Heading, Container, HStack } from "@chakra-ui/react"
import { CustomComponent, ReactifySearchProvider, Sensors, useSearch } from "@usereactify/search"
import { useConfigContext } from "@app/providers/config"
import { useShop } from "@app/hooks/useShop"
import { SearchResults } from "@components/Search/Results/SearchResults"
import { useContent } from "@app/hooks/useContent"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { SearchSuggestedTerms } from "./SearchSuggestedTerms"
import { SearchProductRecommendations } from "./SearchProductRecommendations"
import { SearchResultsBlogs } from "./Blogs/SearchResultsBlogs"
import { SearchBrandBanner } from "./SearchBrandBanner"
import { SearchSort } from "@components/Search/Sort/SearchSort"

import type { Props } from "@app/pages/search"
import type { PageProps } from "@root/types/global"
import { useCore } from "@app/hooks/useCore"

type TabTitleProps = {
  type: string
  setActiveTab: (arg1: string) => void
  active: boolean
}

const TABS = {
  PRODUCTS: "Products",
  BLOGS: "Articles",
} as const

const SearchQuery: React.FC = () => {
  // Separated into its own component since useSearch() must be a child of the provider
  const { searchTerm } = useSearch()
  if (!searchTerm) return null
  return <span>{searchTerm}</span>
}

const TabTitle = ({ type, setActiveTab, active }: TabTitleProps) => (
  <Heading
    fontSize="3xl"
    textDecoration={active ? "underline" : "unset"}
    cursor="pointer"
    onClick={useCallback(() => setActiveTab(type), [setActiveTab, type])}
  >
    {type}
  </Heading>
)

const Search: React.FC<PageProps<Props>> = ({ page, brands }) => {
  const [isEmptyResults, setIsEmptyResults] = useState(false)
  const [isBrandCollection, setIsBrandCollection] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS.PRODUCTS)
  const locales = useLocale(LOCALE_KEYS.SEARCH)
  const { shop } = useShop()
  const { getContent } = useContent()
  const {
    helpers: { isBrowser },
  } = useCore()

  const content = getContent({ content: page?.content })
  const {
    store: { shopifyShopDomain },
  } = useConfigContext()

  const titlePrefix = isEmptyResults ? locales?.searchPageEmptyResultsPrefix : locales?.searchPageHasResultsPrefix

  return isBrowser ? (
    <>
      <ReactifySearchProvider
        mode="search"
        shopifyPermanentDomain={shop?.myshopifyDomain ?? shopifyShopDomain}
        additionalComponentIds={["REVIEW_PRODUCTS"]}
      >
        <Sensors />
        <CustomComponent
          componentId="REVIEW_PRODUCTS"
          customQuery={() => ({
            query: {
              bool: {
                must_not: [
                  {
                    term: {
                      "tags.keyword": "review-product",
                    },
                  },
                ],
              },
            },
          })}
        />
        <Box pb={[16, 16, 24]}>
          <Box bg={isBrandCollection ? "brand.lightSand" : "brand.offWhite"}>
            <Box as="section" mb={[4, 4, 6]}>
              <Container pt={[12, 12, 16, 16]}>
                <Heading as="h1" size={["h3", "h2"]} mb={4}>
                  {titlePrefix} ‘<SearchQuery />’
                </Heading>
                {content}
                <Box pb={isBrandCollection ? 16 : 6}>
                  <SearchSuggestedTerms terms={page?.suggestedSearchTerms} />
                </Box>
              </Container>
              <SearchBrandBanner brands={brands} setIsBrandCollection={setIsBrandCollection} />
            </Box>
          </Box>
          <Container>
            {isEmptyResults && <SearchProductRecommendations recommendedProducts={page?.recommendedProducts} />}
            <Box>
              {!isEmptyResults && (
                <Flex justifyContent="space-between" flexDirection={["column", "column", "row"]}>
                  <HStack spacing={6} mb={[6, 6, 0]}>
                    <TabTitle type={TABS.PRODUCTS} setActiveTab={setActiveTab} active={activeTab === TABS.PRODUCTS} />
                    <TabTitle type={TABS.BLOGS} setActiveTab={setActiveTab} active={activeTab === TABS.BLOGS} />
                  </HStack>
                  <SearchSort isSearchPage />
                </Flex>
              )}
              <Box mt={2.5}>
                {activeTab === TABS.PRODUCTS && (
                  <SearchResults setIsEmptyResults={setIsEmptyResults} isSearchPage isEmptyResults={isEmptyResults} />
                )}
                {activeTab === TABS.BLOGS && <SearchResultsBlogs />}
              </Box>
            </Box>
          </Container>
        </Box>
      </ReactifySearchProvider>
    </>
  ) : null
}

export default memo(Search)
